import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'
import { Icon } from '../components/Icon'

export type PageBodyPartnerHeroProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyPartnerHero = ({
  heading,
  description,
  partnerLogoFluid,
  partnerLogoAlt,
  featuredVideo,
  featuredImageFluid,
  featuredImageAlt,
  websiteHref,
  websiteTarget,
  facebookHref,
  facebookTarget,
  instagramHref,
  instagramTarget,
  twitterHref,
  twitterTarget
}: PageBodyPartnerHeroProps) => {
  const mediaRef = React.useRef(null)

  const playVideo = () => {
    const media = mediaRef?.current

    if (media) {
      const iframe = media.querySelector('iframe')

      media.querySelector('.image-cover').classList.add('hide')
      iframe.src = `${iframe.getAttribute('src')}&autoplay=1`
    }
  }

  return partnerLogoFluid ? (
    <div
      className="partner-hero"
    >
      <div className="partner-hero__logo">
        <GatsbyImage fluid={partnerLogoFluid} alt={partnerLogoAlt} />
      </div>

      {(featuredImageFluid || featuredVideo) && (
        <div className="partner-hero__media" ref={mediaRef}>
          {featuredVideo && (
            <HTMLContent html={featuredVideo} />
          )}

          {featuredImageFluid && (
            <div className="image-cover">
              <GatsbyImage fluid={featuredImageFluid} alt={featuredImageAlt} />
              {featuredVideo && (
                <button
                  className="play-button"
                  type="button"
                  aria-label="play video"
                  onClick={playVideo}
                >
                  <Icon name="play" />
                </button>
              )}
            </div>
          )}
        </div>
      )}

      <div className="partner-hero__content">
        {(heading || description) && (
          <div className="partner-hero__copy">
            <HTMLContent html={heading} />
            <HTMLContent html={description} />
          </div>
        )}

        {(websiteHref || facebookHref || instagramHref || twitterHref) && (
          <div className="partner-hero__links">
          {websiteHref && (
            <Link className="link" href={websiteHref} target={websiteTarget}>
              Website
            </Link>
          )}

          <div className="partner-hero__social">
            {facebookHref && (
              <Link href={facebookHref} target={facebookTarget}>
                <Icon name="facebookCircle" />
              </Link>
            )}

            {instagramHref && (
              <Link href={instagramHref} target={instagramTarget}>
                <Icon name="instagramCircle" />
              </Link>
            )}

            {twitterHref && (
              <Link href={twitterHref} target={twitterTarget}>
                <Icon name="twitterCircle" />
              </Link>
            )}
          </div>
        </div>
        )}
      </div>
    </div>
  ) : null
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyPartnerHeroFragment, typeof mapDataToContext>) => ({
  heading: getRichText(data?.primary?.heading),
  description: getRichText(data?.primary?.description),
  partnerLogoFluid: data?.primary?.partner_logo?.fluid,
  partnerLogoAlt: data?.primary?.partner_logo?.alt,
  featuredVideo: data?.primary?.featured_video.html,
  featuredImageFluid: data?.primary?.featured_image?.fluid,
  featuredImageAlt: data?.primary?.featured_image?.alt,
  websiteHref: data?.primary?.website_link?.url,
  websiteTarget: data?.primary?.website_link?.target,
  facebookHref: data?.primary?.facebook_link?.url,
  facebookTarget: data?.primary?.facebook_link?.target,
  instagramHref: data?.primary?.instagram_link?.url,
  instagramTarget: data?.primary?.instagram_link?.target,
  twitterHref: data?.primary?.twitter_link?.url,
  twitterTarget: data?.primary?.twitter_link?.target,
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyPartnerHero on PrismicPageBodyPartnerHero {
    primary {
      partner_logo {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
      featured_video {
        html
      }
      featured_image {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
      heading {
        text
        html
      }
      description {
        text
        html
      }
      website_link {
        url
        target
      }
      facebook_link {
        url
        target
      }
      instagram_link {
        url
        target
      }
      twitter_link {
        url
        target
      }
    }
  }
`

export default PageBodyPartnerHero