import { useStaticQuery, graphql } from 'gatsby'

import { PrismicSiteSettingsQuery } from '../types.generated'

const YEAR = new Date().getFullYear().toString()

export const useSiteSettings = () => {
  const queryData = useStaticQuery<PrismicSiteSettingsQuery>(graphql`
    query PrismicSiteSettings {
      prismicSettings {
        data {
          site_name {
            text
          }
          site_description {
            text
          }
          site_copyright {
            text
          }
          facebook_handle
          twitter_handle
          instagram_handle
        }
      }
    }
  `)

  const settings = queryData.prismicSettings?.data

  return {
    siteName: settings?.site_name?.text,
    siteDescription: settings?.site_description?.text,
    siteCopyright: settings?.site_copyright?.text?.replace?.(/\$YEAR/g, YEAR),
    facebookHandle: settings?.facebook_handle,
    facebookURL: settings?.facebook_handle
      ? `https://facebook.com/${settings?.facebook_handle}`
      : undefined,
    twitterHandle: settings?.twitter_handle,
    twitterURL: settings?.twitter_handle
      ? `https://twitter.com/${settings?.twitter_handle}`
      : undefined,
    instagramHandle: settings?.instagram_handle,
    instagramURL: settings?.instagram_handle
      ? `https://instagram.com/${settings?.instagram_handle}`
      : undefined,
  }
}
