import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'

export type PageBodyPageTitleProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyPageTitle = ({
  heading,
  subheading,
  poweredBy
}: PageBodyPageTitleProps) => heading ? (
  <div className="page-title">
    {poweredBy && (
      <h6>Powered By</h6>
    )}

    {subheading && (
      <HTMLContent html={subheading} />
    )}

    <HTMLContent html={heading} />
  </div>
) : null

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyPageTitleFragment, typeof mapDataToContext>) => ({
  heading: getRichText(data?.primary?.heading),
  subheading: getRichText(data?.primary?.subheading),
  poweredBy: data?.primary?.powered_by
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyPageTitle on PrismicPageBodyPageTitle {
    primary {
      heading {
        text
        html
      }
      subheading {
        text
        html
      }
      powered_by
    }
  }
`

export default PageBodyPageTitle
