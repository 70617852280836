import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'


import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'

export type PageBodyTextProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyText = ({
  textHTML
}: PageBodyTextProps) => textHTML ? (
  <HTMLContent className="copy" html={textHTML} />
) : null

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyTextFragment, typeof mapDataToContext>) => ({
  textHTML: getRichText(data?.primary?.text),
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyText on PrismicPageBodyText {
    primary {
      text {
        text
        html
      }
    }
  }
`

export default PageBodyText
