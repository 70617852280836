import * as React from 'react'
import 'react-dates/initialize'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import 'react-dates/lib/css/_datepicker.css';

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'

import race5kSquare from '../assets/race-day-5k-square.png'
import race5kVertical from '../assets/race-day-5k-vertical.png'
import raceHalfSquare from '../assets/race-day-half-marathon-square.png'
import raceHalfVertical from '../assets/race-day-half-marathon-vertical.png'
import raceKidsSquare from '../assets/race-day-kids-square.png'
import raceKidsVertical from '../assets/race-day-kids-vertical.png'
import raceMarathonSquare from '../assets/race-day-marathon-square.png'
import raceMarathonVertical from '../assets/race-day-marathon-vertical.png'
import racePetSquare from '../assets/race-day-pet-square.png'
import racePetVertical from '../assets/race-day-pet-vertical.png'
import raceRelaySquare from '../assets/race-day-relay-square.png'
import raceRelayVertical from '../assets/race-day-relay-vertical.png'
import raceToddlerSquare from '../assets/race-day-toddler-trot-square.png'
import raceToddlerVertical from '../assets/race-day-toddler-trot-vertical.png'
import raceVirtualSquare from '../assets/race-day-virtual-square.png'
import raceVirtualVertical from '../assets/race-day-virtual-vertical.png'


export type PageBodyPledgeProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyPledge = ({
  pledgeMessage,
  formInstructions,
  downloadInstructions,
}: PageBodyPledgeProps) => {
  const [activePage, setActivePage] = React.useState('form')
  const [name, setName] = React.useState('')
  const [race, setRace] = React.useState('marathon')

  const canvas = React.useRef(null)
  const verticalCanvas = React.useRef(null)

  const renderCanvas = (
    item: React.MutableRefObject<null>,
    width: number,
    height: number
  ) => {
    setActivePage('share')
    const c = item?.current?.getContext('2d')
    let selectedDate = "Sunday, May 1"

    if (c) {
      c.textAlign = 'center';

      const bg = new Image()

      if (race === 'marathon') {
        if (height > width) {
          bg.src = raceMarathonVertical
        } else {
          bg.src = raceMarathonSquare
        }
      } else if (race === 'half') {
        if (height > width) {
          bg.src = raceHalfVertical
        } else {
          bg.src = raceHalfSquare
        }
      } else if (race === 'relay') {
        if (height > width) {
          bg.src = raceRelayVertical
        } else {
          bg.src = raceRelaySquare
        }
      } else if (race === '5k') {
        if (height > width) {
          bg.src = race5kVertical
        } else {
          bg.src = race5kSquare
        }
      } else if (race === 'kids') {
        if (height > width) {
          bg.src = raceKidsVertical
        } else {
          bg.src = raceKidsSquare
        }
      } else if (race === 'toddler') {
        if (height > width) {
          bg.src = raceToddlerVertical
        } else {
          bg.src = raceToddlerSquare
        }
      } else if (race === 'pet') {
        if (height > width) {
          bg.src = racePetVertical
        } else {
          bg.src = racePetSquare
        }
      } else if (race === 'virtual') {
        if (height > width) {
          bg.src = raceVirtualVertical
        } else {
          bg.src = raceVirtualSquare
        }
      }

      if (['5k', 'kids', 'toddler', 'pet'].includes(race)) {
        selectedDate = "Saturday, April 30"
      } else if (race === 'virtual') {
        selectedDate = "Virtual Runner"
      }

      c.drawImage(bg, 0, 0, width, height)

      const x = 540

      c.font = 'bold 80pt soleil'
      c.fillStyle = '#000'

      let dateY = height > width ? 980 : 630;

      if (race === 'virtual') {
        dateY = height > width ? 960 : 610;
      }

      c.fillText(selectedDate, x, dateY)

      c.fillStyle = '#8cb6e9'
      c.font = 'bold 45pt soleil'

      if (name) {
        let nameY = height > width ? 775 : 425;

        if (race === 'virtual') {
          nameY = height > width ? 795 : 445;
          c.fillText(`${name.toUpperCase()}`, x, nameY)
        } else {
          c.fillText(`${name.toUpperCase()}'S`, x, nameY)
        }
      }

      if (race !== 'virtual') {
        const raceDayY = height > width ? 840 : 490;
        c.fillText('RACE DAY', x, raceDayY)
      }

      c.font = 'bold 60pt soleil'
      let messageY = height > width ? 1110 : 760;

      if (race === 'virtual') {
        messageY = height > width ? 1000 : 750;
      }

      c.fillText('Wish me luck!', x, messageY)
    }
  }

  const download = (item: React.MutableRefObject<null>) => {
    const image = item?.current?.toDataURL('image/png', 1.0)

    if (image) {
      const a = document.createElement('a');
      a.href = image;
      a.download = 'pledge-to-run';

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(image);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };

      a.addEventListener('click', clickHandler, false);
      a.click();
    }
  }

  return (
    <div className="pledge">
      <div className={`pledge__form ${activePage === 'form' ? 'show' : ''}`}>
        <span
          className="cheer-card__vertical-background"
          style={{
            backgroundImage: race === 'marathon'
              ? `url('${raceMarathonSquare}')`
              : race === 'half'
              ? `url('${raceHalfSquare}')`
              : race === 'relay'
              ? `url('${raceRelaySquare}')`
              : race === '5k'
              ? `url('${race5kSquare}')`
              : race === 'kids'
              ? `url('${raceKidsSquare}')`
              : race === 'toddler'
              ? `url('${raceToddlerSquare}')`
              : race === 'pet'
              ? `url('${racePetSquare}')`
              : race === 'virtual'
              ? `url('${raceVirtualSquare}')`
              : ''
          }}
        ></span>

        <span
          className="cheer-card__vertical-background"
          style={{
            backgroundImage: race === 'marathon'
              ? `url('${raceMarathonVertical}')`
              : race === 'half'
              ? `url('${raceHalfVertical}')`
              : race === 'relay'
              ? `url('${raceRelayVertical}')`
              : race === '5k'
              ? `url('${race5kVertical}')`
              : race === 'kids'
              ? `url('${raceKidsVertical}')`
              : race === 'toddler'
              ? `url('${raceToddlerVertical}')`
              : race === 'pet'
              ? `url('${racePetVertical}')`
              : race === 'virtual'
              ? `url('${raceVirtualVertical}')`
              : ''
          }}
        ></span>

        <form>
          {pledgeMessage && (
            <div className="pledge-message">
              <HTMLContent html={pledgeMessage} />
            </div>
          )}
          {formInstructions && (<HTMLContent html={formInstructions} />)}

          <label>
            Name
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </label>

          <label className="dropdown">
            Race
            <select value={race} onChange={(e) => setRace(e.target.value)}>
              <option value="marathon">Marathon</option>
              <option value="half">Half Marathon</option>
              <option value="relay">Marathon Relay</option>
              <option value="5k">5k</option>
              <option value="kids">Kids Marathon</option>
              <option value="toddler">Toddler Trot</option>
              <option value="pet">Pet Walk</option>
              <option value="virtual">Virtual</option>
            </select>
          </label>

          <button
            type="button"
            className="button"
            onClick={() => {
              renderCanvas(canvas, 1080, 1080)
              renderCanvas(verticalCanvas, 1080, 1920)
            }}
          >
            Create Badge
          </button>
        </form>
      </div>

      <div className={`cheer-card__share ${activePage === 'share' ? 'show' : ''}`}>
        <div className="cheer-card__preview">
          <canvas width="1080" height="1080" ref={canvas}></canvas>
        </div>

        <div className="cheer-card__preview cheer-card__preview--vertical">
          <canvas width="1080" height="1920" ref={verticalCanvas}></canvas>
        </div>

        <div className="cheer-card__right">
          <h3>Downloads</h3>
          <HTMLContent html={downloadInstructions} />

          <div className="pledge__downloads">
            <button className="link link--large" onClick={() => download(canvas)}>
              Square
            </button>

            <button className="link link--large" onClick={() => download(verticalCanvas)}>
              Vertical
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyPledgeFragment, typeof mapDataToContext>) => ({
  heading: getRichText(data?.primary?.heading),
  pledgeMessage: getRichText(data?.primary?.pledge_message),
  formInstructions: getRichText(data?.primary?.form_instructions),
  downloadInstructions: getRichText(data?.primary?.download_instructions),
  children: [...data?.items?.map((item) => {
    return {
      raceDate: item?.race_date,
      runnerCount: item?.runner_count
    }
  })]
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyPledge on PrismicPageBodyPledge {
    primary {
      heading {
        text
        html
      }
      pledge_message {
        text
        html
      }
      form_instructions {
        text
        html
      }
      download_instructions {
        text
        html
      }
    }
    items {
      race_date
      runner_count
    }
  }
`

export default PageBodyPledge