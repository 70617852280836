import * as React from 'react'
import { graphql } from 'gatsby'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import MapSlicesToComponents from '@walltowall/react-map-slices-to-components'
import { reshapeSlicesMap } from '../lib/mapSlicesToComponents'

import * as PageBodyText from './PageBodyText'
import * as PageBodyHomeHero from './PageBodyHomeHero'
import * as PageBodyToolkit from './PageBodyToolkit'
import * as PageBodyPageTitle from './PageBodyPageTitle'
import * as PageBodyCheerCard from './PageBodyCheerCard'
import * as PageBodyPoweredBy from './PageBodyPoweredBy'
import * as PageBodyPledge from './PageBodyPledge'
import * as PageBodyWhyIRunWall from './PageBodyWhyIRunWall'
import * as PageBodyImagePromo from './PageBodyImagePromo'
import * as PageBodyRaceCertificate from './PageBodyRaceCertificate'
import * as PageBodyFeaturedPartner from './PageBodyFeaturedPartner'
import * as PageBodyPartnerCategory from './PageBodyPartnerCategory'
import * as PageBodyPartnerHero from './PageBodyPartnerHero'
import * as PageBodyAdPromo from './PageBodyAdPromo'
import * as PageBodyFeaturedVideo from './PageBodyFeaturedVideo'
import * as PageBodyFeaturedLink from './PageBodyFeaturedLink'
import * as PageBodyPartnerLinks from './PageBodyPartnerLinks'
import * as PageBodyOfferPromo from './PageBodyOfferPromo'
import * as PageBodyVideoSlider from './PageBodyVideoSlider'
import * as PageBodyStats from './PageBodyStats'
import * as PageBodyHtmlEmbed from './PageBodyHtmlEmbed'
import * as PageBodyImageWithCopy from './PageBodyImageWithCopy'

export type PageBodyChildTabsProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const slicesMap = reshapeSlicesMap({
  PageBodyText,
  PageBodyHomeHero,
  PageBodyToolkit,
  PageBodyPageTitle,
  PageBodyCheerCard,
  PageBodyPoweredBy,
  PageBodyPledge,
  PageBodyWhyIRunWall,
  PageBodyImagePromo,
  PageBodyRaceCertificate,
  PageBodyFeaturedPartner,
  PageBodyPartnerCategory,
  PageBodyPartnerHero,
  PageBodyAdPromo,
  PageBodyFeaturedVideo,
  PageBodyFeaturedLink,
  PageBodyPartnerLinks,
  PageBodyOfferPromo,
  PageBodyVideoSlider,
  PageBodyStats,
  PageBodyHtmlEmbed,
  PageBodyImageWithCopy
})

export const slicesMiddleware = <T,>(list: T[]) => [
  ...list
]

export const mapDataToPropsEnhancer = (
  props: Record<string, unknown> | undefined
) => ({
  ...props,
})

const PageBodyChildTabs = ({
  rootData
}: PageBodyChildTabsProps) => {
  const [activeTab, setActiveTab] = React.useState(rootData?.items
    ? rootData?.items[0]?.child?.document?.data?.title?.text
    : ''
  )

  const meta = React.useMemo(
    () => ({
      rootData: rootData,
    }),
    [rootData],
  )

  return rootData?.items ? (
    <div className="child-tabs">
      <div className="tabs">
        {rootData?.items.map((item, index) => (
          <button
            key={index}
            className={`tab ${activeTab === item?.child?.document?.data?.title?.text ? 'tab--active' : ''}`}
            onClick={() => setActiveTab(item?.child?.document?.data?.title?.text)}
          >
            {item?.child?.document?.data?.title?.text}
          </button>
        ))}
      </div>

      {rootData?.items.map((item, index) => (
        <div className={`tab-content ${activeTab === item?.child?.document?.data?.title?.text ? 'tab-content--active' : ''}`} key={index}>
          <MapSlicesToComponents
            list={item?.child?.document?.data?.body}
            map={slicesMap}
            meta={meta}
            listMiddleware={slicesMiddleware}
            mapDataToPropsEnhancer={mapDataToPropsEnhancer}
          />
        </div>
      ))}
    </div>
  ) : null
}

export const mapDataToProps = ({
  data
}: MapDataToPropsArgs<PageBodyChildTabsFragment, typeof mapDataToContext>) => ({
  rootData: data
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyChildTabs on PrismicPageBodyChildTabs {
    items {
      child {
        document {
          __typename
          ... on PrismicPage {
            data {
              title {
                text
              }
              body {
                __typename
                ... on Node {
                  id
                }
                ... on PrismicPageBodySlicesType {
                  ...PageBodyText
                  ...PageBodyHomeHero
                  ...PageBodyToolkit
                  ...PageBodyPageTitle
                  ...PageBodyCheerCard
                  ...PageBodyPoweredBy
                  ...PageBodyPledge
                  ...PageBodyWhyIRunWall
                  ...PageBodyImagePromo
                  ...PageBodyRaceCertificate
                  ...PageBodyFeaturedPartner
                  ...PageBodyPartnerCategory
                  ...PageBodyPartnerHero
                  ...PageBodyAdPromo
                  ...PageBodyFeaturedVideo
                  ...PageBodyFeaturedLink
                  ...PageBodyPartnerLinks
                  ...PageBodyOfferPromo
                  ...PageBodyVideoSlider
                  ...PageBodyStats
                  ...PageBodyHtmlEmbed
                  ...PageBodyImageWithCopy
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageBodyChildTabs