import * as React from 'react'
import { PageTemplateEnhancerProps } from '../templates/page'

import { Link } from '../components/Link'
import { Icon } from '../components/Icon'
import background from '../assets/background-header.png';
import largeBackground from '../assets/background-header-large.png'


export type PageBodyHeaderProps = PageTemplateEnhancerProps

const PageBodyHeader = () => {
  return (
    <header>
      <Link href="/" className="logo">
        <Icon name='logo' />
        <div className="logo__text">
          <span>Experience</span>
        </div>
      </Link>

      <img src={background} alt="" className="header__background" aria-hidden="true" />
      <img src={largeBackground} alt="" className="header__background header__background--large" aria-hidden="true" />

      <div className="presented-by">
        <Icon name="presentedByBrooks" />
      </div>
    </header>
  )
}

export const mapDataToContext = () => ({})

export default PageBodyHeader
