import * as React from 'react'
import clsx from 'clsx'
import { Link, LinkProps } from './Link'

import ribbon from '../assets/ribbon.png'

export type FinishedButtonProps = LinkProps & {
  className?: string
}

export const FinishedButton = ({
  className,
  ...props
}: FinishedButtonProps): JSX.Element => {
  const [expanded, setExpanded] = React.useState(false)
  const [pinned, setPinned] = React.useState(false)
  const buttonRef = React.useRef(null)

  React.useEffect(() => {
    document.addEventListener('scroll', changeSize)
    document.addEventListener('DOMContentLoaded', changeSize)
    document.addEventListener('resize', changeSize)

    return () => {
      document.removeEventListener('scroll', changeSize)
      document.removeEventListener('DOMContentLoaded', changeSize)
      document.removeEventListener('resize', changeSize)
    }
  }, [])

  const changeSize = () => {
    const footer = document.querySelector('.page-footer')
    const footerTop = footer?.getBoundingClientRect()?.top

    const buttonTop = buttonRef?.current?.getBoundingClientRect()?.top

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 260) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }

    if (!isNaN(footerTop) && !isNaN(buttonTop)) {
      if (footerTop < buttonTop) {
        setPinned(true)
      } else if (buttonTop > window.innerHeight - 154) {
        setPinned(false)
      }
    }
  }

  return (
    <div className="finished-button-wrapper">
      <Link
        {...props}
        className={clsx(
          className,
          expanded ? 'expanded' : '',
          pinned ? 'pinned' : ''
        )}
      >
        <span ref={buttonRef}>
          <img src={ribbon} alt="ribbon" />
          I Finished my Race
        </span>
      </Link>
    </div>
  )
}