import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { getRichText } from '@walltowall/helpers'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'
import { Icon } from '../components/Icon'
import Slider from 'react-slick'

export type PageBodyVideoSliderProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  variableWidth: true
}

const MoveDragThreshold = 10;

function useDragDetection(): {
  handleMouseDown: () => void
  dragging: boolean
} {
  const [mouseDown, setMouseDown] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);

  React.useEffect(() => {
    let mouseMove = 0;

    function handleMouseUp(): void {
      setMouseDown(false);
    }

    function handleMouseMove(e: MouseEvent): void {
      mouseMove += Math.abs(e.movementX) + Math.abs(e.movementY);
      setDragging(mouseMove > MoveDragThreshold);
    }

    if (mouseDown) {
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [mouseDown]);

  function handleMouseDown(): void {
    setMouseDown(true);
    setDragging(false);
  }

  return {
    handleMouseDown,
    dragging,
  };
}

const PageBodyVideoSlider = ({
  category,
  children
}: PageBodyVideoSliderProps) => (
  <div
    className="toolkit"
  >
    <HTMLContent html={category} />

    <div className="toolkit__list">
      <Slider
        {...settings}
      >
        {children}
      </Slider>
    </div>
  </div>
)

type CardProps = {
  coverImageFluid?: FluidObject
  coverImageAlt?: string
  videoTitle?: string
  embed?: string
  href?: string
}

const Card = ({
  coverImageFluid,
  coverImageAlt,
  videoTitle,
  href
}: CardProps) => {
  const {
    handleMouseDown,
    dragging,
  } = useDragDetection();

  function handleChildClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    if (dragging) {
      e.preventDefault();
    }
  }

  return coverImageFluid && href ? (
    <Link className="video-card" href={href}>
      <div className="video-card__video">

        <div className="image-cover">
          <GatsbyImage fluid={coverImageFluid} alt={coverImageAlt} />

          <div
            className="play-button"
          >
            <Icon name="play" />
          </div>
        </div>
      </div>

      <div className="video-card__link">
        {videoTitle && (
          <HTMLContent html={videoTitle} />
        )}
      </div>
    </Link>
  ) : null
}

PageBodyVideoSlider.Card = Card

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyToolkitFragment, typeof mapDataToContext>) => ({
  category: getRichText(data?.primary?.category),
  children: data?.items?.map((item, index) => (
    <PageBodyVideoSlider.Card
      key={index}
      coverImageFluid={item?.cover_image?.fluid}
      coverImageAlt={item?.cover_image?.alt}
      videoTitle={item?.video_title}
      href={item?.video_link?.url}
    />
  )) as React.ReactNode
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyVideoSlider on PrismicPageBodyVideoSlider {
    primary {
      category {
        text
        html
      }
    }
    items {
      video_title
      cover_image {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
      video_link {
        url
        target
      }
    }
  }
`

export default PageBodyVideoSlider