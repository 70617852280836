import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Icon } from '../components/Icon'

export type PageBodyVideoDetailsProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyVideoDetails = ({
  videoTitle,
  videoTitleString,
  posterFluid,
  posterAlt,
  videoEmbed,
  description
}: PageBodyVideoDetailsProps) => {
  const mediaRef = React.useRef(null)
  const shareRef = React.useRef(null)

  React.useEffect(() => {
    if (shareRef?.current && navigator.share) {
      shareRef.current.innerHTML = `
        <button class="link">
          Share
        </button>
      `

      const button = shareRef.current.querySelector('.link')

      if (button && location) {
        button.addEventListener('click', () => {
          navigator.share({
            title: videoTitleString,
            url: location.href
          })
          .catch(console.error);
        })
      }
    }
  }, [])

  const playVideo = () => {
    const media = mediaRef?.current

    if (media) {
      const iframe = media.querySelector('iframe')

      media.querySelector('.image-cover').classList.add('hide')
      media.querySelector('.video-embed').classList.add('show')
      iframe.src = `${iframe.getAttribute('src')}&autoplay=1`
    }
  }

  return videoEmbed ? (
  <div className="featured-video video-details">
    <div className="featured-video__item">
      <div className="featured-video__item-wrapper">
        <div className="featured-video__item__video" ref={mediaRef}>
          <HTMLContent className="video-embed" html={videoEmbed} />

          {posterFluid && (
          <div className="image-cover featured-video__media">
            <GatsbyImage fluid={posterFluid} alt={posterAlt}/>

            <button
              className="play-button"
              type="button"
              aria-label="play video"
              onClick={playVideo}
            >
              <Icon name="play" />
            </button>
          </div>
          )}
        </div>
      </div>

      <div className="featured-video__item__title">
        {videoTitle && (
          <HTMLContent html={videoTitle} />
        )}

        <HTMLContent html={description} />
      </div>

      <div className="share-button" ref={shareRef}>

      </div>
    </div>
  </div>
  ) : null
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyVideoDetailsFragment, typeof mapDataToContext>) => ({
  posterFluid: data?.primary?.cover_image?.fluid,
  posterAlt: data?.primary?.cover_image?.alt,
  videoTitle: getRichText(data?.primary?.video_title),
  videoTitleString: data?.primary?.video_title?.text,
  videoEmbed: data?.primary?.video_embed.html,
  description: getRichText(data?.primary?.video_description)
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyVideoDetails on PrismicPageBodyVideoDetails {
    primary {
      cover_image {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
      video_title {
        text
        html
      }
      video_embed {
        html
      }
      video_description {
        text
        html
      }
    }
  }
`

export default PageBodyVideoDetails
