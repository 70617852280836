import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'

export type PageBodyAdPromoProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyAdPromo = ({
  adCopy,
  adLink,
  brandLogoFluid,
  brandLogoAlt,
  imageCutoutFluid,
  imageCutoutAlt,
}: PageBodyAdPromoProps) => brandLogoFluid && adLink ? (
  <div className="partner-block-wrapper">
    <Link href={adLink} className="partner-block" >
      <div className="partner-block__content">
        <GatsbyImage className="partner-block__content__logo" fluid={brandLogoFluid} alt={brandLogoAlt}/>
        <HTMLContent html={adCopy} />
      </div>
      {imageCutoutFluid && (
        <GatsbyImage className="partner-block__cutout" fluid={imageCutoutFluid} alt={imageCutoutAlt}/>
      )}
    </Link>
  </div>
) : null

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyAdPromoFragment, typeof mapDataToContext>) => ({
  adCopy: getRichText(data?.primary?.ad_copy),
  adLink: data?.primary?.ad_link?.url,
  brandLogoFluid: data?.primary?.brand_logo?.fluid,
  brandLogoAlt: data?.primary?.brand_logo?.alt,
  imageCutoutFluid: data?.primary?.image_cutout?.fluid,
  imageCutoutAlt: data?.primary?.image_cutout?.alt,
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyAdPromo on PrismicPageBodyAdPromo {
    primary {
      ad_copy {
        text
        html
      }
      ad_link {
        url
        target
      }
      brand_logo {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
      image_cutout {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyAdPromo
