import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'

const defaultElement = 'div'

type TextOwnProps = {
  children?: React.ReactNode
}

export type TextProps<
  T extends React.ElementType = typeof defaultElement
> = PolymorphicPropsWithoutRef<TextOwnProps, T>

export const Text = <T extends React.ElementType = typeof defaultElement>({
  as,
  className,
  ...restProps
}: TextProps<T>) => {
  const Element: React.ElementType = as ?? defaultElement

  return (
    <Element {...restProps} className={className} />
  )
}
