import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import ConditionalWrap from 'conditional-wrap'
import { Link } from '../components/Link'

export type PageBodyPoweredByProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyPoweredBy = ({
  sponsorHref,
  sponsorTarget,
  logoImageFluid,
  logoImageAlt
}: PageBodyPoweredByProps) => logoImageFluid ? (
  <div className="powered-by-wrapper">
    <div className="powered-by">
        <h4>Powered by</h4>

        <ConditionalWrap
          condition={sponsorHref}
          wrap={children => (
            <Link href={sponsorHref} target={sponsorTarget}>
              {children}
            </Link>
          )}
        >
          <GatsbyImage fluid={logoImageFluid} alt={logoImageAlt} />
        </ConditionalWrap>
    </div>
  </div>
): null

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyPoweredByFragment, typeof mapDataToContext>) => ({
  sponsorHref: data?.primary?.sponsor_link?.url,
  sponsorTarget: data?.primary?.sponsor_link?.target,
  logoImageFluid: data?.primary?.sponsor_logo?.fluid,
  logoImageAlt: data?.primary?.sponsor_logo?.alt
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyPoweredBy on PrismicPageBodyPoweredBy {
    primary {
      sponsor_link {
        url
        target
      }
      sponsor_logo {
        alt
        fluid(maxWidth: 200) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyPoweredBy
