import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import TextareaAutosize from 'react-textarea-autosize';

import thumbTrackers from '../assets/thumb-trackers.png'
import squareTrackers from '../assets/cheer-trackers-square.png'
import verticalTrackers from '../assets/cheer-trackers-vertical.png'
import thumbShoes from '../assets/thumb-shoes.png'
import squareShoes from '../assets/cheer-shoes-square.png'
import verticalShoes from '../assets/cheer-shoes-vertical.png'
import thumbBottles from '../assets/thumb-bottles.png'
import squareBottles from '../assets/cheer-bottles-square.png'
import verticalBottles from '../assets/cheer-bottles-vertical.png'
import thumbBananas from '../assets/thumb-bananas.png'
import squareBananas from '../assets/cheer-bananas-square.png'
import verticalBananas from '../assets/cheer-bananas-vertical.png'


export type PageBodyCheerCardProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyCheerCard = ({
  description,
  downloadInstructions
}: PageBodyCheerCardProps) => {
  const [activePage, setActivePage] = React.useState('edit')
  const [activeBackground, setActiveBackground] = React.useState(0)
  const [message, setMessage] = React.useState('')

  const canvas = React.useRef(null)
  const verticalCanvas = React.useRef(null)

  const renderCanvas = (
    item: React.MutableRefObject<null>,
    width: number,
    height: number
  ) => {
    setActivePage('share')
    const c = item?.current?.getContext('2d')

    if (c) {
      c.clearRect(0, 0, width, height);
      c.textAlign = 'center';

      // Adds canvas background
      const bg = new Image()

      if (activeBackground === 0) {
        if (height > width) {
          bg.src = verticalTrackers
        } else {
          bg.src = squareTrackers
        }
      } else if (activeBackground === 1) {
        if (height > width) {
          bg.src = verticalShoes
        } else {
          bg.src = squareShoes
        }
      } else if (activeBackground === 2) {
        if (height > width) {
          bg.src = verticalBottles
        } else {
          bg.src = squareBottles
        }
      } else if (activeBackground === 3) {
        if (height > width) {
          bg.src = verticalBananas
        } else {
          bg.src = squareBananas
        }
      }

      c.drawImage(bg, 0, 0, width, height)

      c.font = 'bold 72pt soleil'
      c.fillStyle = '#000'

      const maxWidth = 748;
      const lineHeight = 100;
      const x = 520;
      const y = height > width ? 1025 : 650;

      // display wrapped text on canvas
      wrapText(c, message, x, y, maxWidth, lineHeight)
    }
  }

  const wrapText = (
    context: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number
  ) => {
    const manualBreaks = text.split('\n')
    const lines = []

    // show manual line breaks on new lines
    for (let n = 0; n < manualBreaks.length; n++) {
      const testLine = manualBreaks[n]
      const metrics = context.measureText(testLine)
      const testWidth = metrics.width

      if (testWidth > maxWidth) {
        const words = testLine.split(' ')
        let innerLine = ''

        // show automatic word wrapping on new lines
        for(let j = 0; j < words.length; j++) {
          const innerTestLine = innerLine + words[j] + ' ';
          const innerMetrics = context.measureText(innerTestLine);
          const innerTestWidth = innerMetrics.width;

          if (innerTestWidth > maxWidth && j > 0) {
            lines.push(innerLine.trim())
            innerLine = words[j] + ' ';
          }
          else {
            innerLine = innerTestLine;
          }
        }

        lines.push(innerLine.trim())
      } else {
        lines.push(testLine)
      }
    }

    // calculate vertical positioning of each line of text
    for(let i = 0; i < lines.length; i++) {
      const start = -(lineHeight / 2) + ((lineHeight / 2) * i)
      const linesAfter = lines.length - (i + 1)
      const verticalDifference = start - ((lineHeight / 2) * linesAfter)

      const lineY = y + verticalDifference

      context.fillText(lines[i], x, lineY)
    }
  }

  const download = (item: React.MutableRefObject<null>) => {
    const image = item?.current?.toDataURL('image/png', 1.0)

    if (image) {
      const a = document.createElement('a');
      a.href = image;
      a.download = 'cheer-card';

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(image);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };

      a.addEventListener('click', clickHandler, false);
      a.click();
    }
  }

  return (
    <div
      className="cheer-card"
    >
      <div className={`cheer-card__edit ${activePage === 'edit' ? 'show' : ''}`}>
        <HTMLContent html={description} className="description" />

        <div className="cheer-card__input">
          <span
            className="cheer-card__vertical-background"
            style={{
              backgroundImage: activeBackground === 0
                ? `url('${verticalTrackers}')`
                : activeBackground === 1
                ? `url('${verticalShoes}')`
                : activeBackground === 2
                ? `url('${verticalBottles}')`
                : activeBackground === 3
                ? `url('${verticalBananas}')`
                : ''
            }}
          ></span>

          <div className="cheer-card__input__background" style={{
            backgroundImage: activeBackground === 0
              ? `url('${squareTrackers}')`
              : activeBackground === 1
              ? `url('${squareShoes}')`
              : activeBackground === 2
              ? `url('${squareBottles}')`
              : activeBackground === 3
              ? `url('${squareBananas}')`
              : ''
          }}>
            <div>
              <TextareaAutosize
                maxRows={4}
                maxLength={52}
                placeholder="Enter your message here..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></TextareaAutosize>
            </div>
          </div>
        </div>

        <div className="cheer-card__right">
          <h3>Backgrounds</h3>

          <ul>
            <li
              className={activeBackground === 0 ? 'active' : ''}
            >
              <button aria-label="Select fitness tracker background" onClick={() => setActiveBackground(0)}>
                <img src={thumbTrackers} alt="fitness trackers" />
              </button>
            </li>

            <li
              className={activeBackground === 1 ? 'active' : ''}
            >
              <button aria-label="Select brooks shoes background" onClick={() => setActiveBackground(1)}>
                <img src={thumbShoes} alt="brooks shoes" />
              </button>
            </li>

            <li
              className={activeBackground === 2 ? 'active' : ''}
            >
              <button aria-label="Select marathon branded bottles background" onClick={() => setActiveBackground(2)}>
                <img src={thumbBottles} alt="Marathon branded bottles" />
              </button>
            </li>

            <li
              className={activeBackground === 3 ? 'active' : ''}
            >
              <button aria-label="Select bananas background" onClick={() => setActiveBackground(3)}>
                <img src={thumbBananas} alt="bananas" />
              </button>
            </li>
          </ul>

          <button
            className="button"
            onClick={() => {
              renderCanvas(canvas, 1080, 1080)
              renderCanvas(verticalCanvas, 1080, 1920)
            }}
          >
            Next
          </button>
        </div>
      </div>

      <div className={`cheer-card__share ${activePage === 'share' ? 'show' : ''}`}>
        <div className="back-button">
          <button
            className="link"
            onClick={() => setActivePage('edit')}
          >
            Back to Edit
          </button>
        </div>

        <div className="cheer-card__preview">
          <canvas width="1080" height="1080" ref={canvas}></canvas>
        </div>

        <div className="cheer-card__preview cheer-card__preview--vertical">
          <canvas width="1080" height="1920" ref={verticalCanvas}></canvas>
        </div>

        <div className="cheer-card__right">
          <h3>Downloads</h3>
          <HTMLContent html={downloadInstructions} />

          <div className="cheer-card__downloads">
            <button className="link link--large" onClick={() => download(canvas)}>
              Square
            </button>

            <button className="link link--large" onClick={() => download(verticalCanvas)}>
              Vertical
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyHomeHeroFragment, typeof mapDataToContext>) => ({
  description: getRichText(data?.primary?.description),
  downloadInstructions: getRichText(data?.primary?.download_instructions),
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyCheerCard on PrismicPageBodyCheerCard {
    primary {
      description {
        text
        html
      }
      download_instructions {
        text
        html
      }
    }
  }
`

export default PageBodyCheerCard
