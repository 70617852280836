/**
 * Root Prismic slices file where Page Body slices are registered for use in the
 * page template file.
 *
 * @see /docs/guide-create-a-slice.md for more details.
 */

import { graphql } from 'gatsby'

import { reshapeSlicesMap } from '../lib/mapSlicesToComponents'

// 1. Import your slice
import * as PageBodyFooter from './PageBodyFooter'
import * as PageBodyHeader from './PageBodyHeader'
import * as PageBodyText from './PageBodyText'
import * as PageBodyHomeHero from './PageBodyHomeHero'
import * as PageBodyToolkit from './PageBodyToolkit'
import * as PageBodyPageTitle from './PageBodyPageTitle'
import * as PageBodyCheerCard from './PageBodyCheerCard'
import * as PageBodyPoweredBy from './PageBodyPoweredBy'
import * as PageBodyPledge from './PageBodyPledge'
import * as PageBodyWhyIRunWall from './PageBodyWhyIRunWall'
import * as PageBodyImagePromo from './PageBodyImagePromo'
import * as PageBodyRaceCertificate from './PageBodyRaceCertificate'
import * as PageBodyFeaturedPartner from './PageBodyFeaturedPartner'
import * as PageBodyPartnerCategory from './PageBodyPartnerCategory'
import * as PageBodyPartnerHero from './PageBodyPartnerHero'
import * as PageBodyAdPromo from './PageBodyAdPromo'
import * as PageBodyFeaturedVideo from './PageBodyFeaturedVideo'
import * as PageBodyFeaturedLink from './PageBodyFeaturedLink'
import * as PageBodyPartnerLinks from './PageBodyPartnerLinks'
import * as PageBodyOfferPromo from './PageBodyOfferPromo'
import * as PageBodyChildTabs from './PageBodyChildTabs'
import * as PageBodyVideoSlider from './PageBodyVideoSlider'
import * as PageBodyStats from './PageBodyStats'
import * as PageBodyVideoDetails from './PageBodyVideoDetails'
import * as PageBodyHtmlEmbed from './PageBodyHtmlEmbed'
import * as PageBodyImageWithCopy from './PageBodyImageWithCopy'

// 2. Add your slice
const slices = {
  PageBodyFooter,
  PageBodyHeader,
  PageBodyText,
  PageBodyHomeHero,
  PageBodyToolkit,
  PageBodyPageTitle,
  PageBodyCheerCard,
  PageBodyPoweredBy,
  PageBodyPledge,
  PageBodyWhyIRunWall,
  PageBodyImagePromo,
  PageBodyRaceCertificate,
  PageBodyFeaturedPartner,
  PageBodyPartnerCategory,
  PageBodyPartnerHero,
  PageBodyAdPromo,
  PageBodyFeaturedVideo,
  PageBodyFeaturedLink,
  PageBodyPartnerLinks,
  PageBodyOfferPromo,
  PageBodyChildTabs,
  PageBodyVideoSlider,
  PageBodyStats,
  PageBodyVideoDetails,
  PageBodyHtmlEmbed,
  PageBodyImageWithCopy
}

// 3. Add your slice fragment
export const fragment = graphql`
  fragment SlicesPageBody on PrismicPageBodySlicesType {
    ...PageBodyText
    ...PageBodyHomeHero
    ...PageBodyToolkit
    ...PageBodyPageTitle
    ...PageBodyCheerCard
    ...PageBodyPoweredBy
    ...PageBodyPledge
    ...PageBodyWhyIRunWall
    ...PageBodyImagePromo
    ...PageBodyRaceCertificate
    ...PageBodyFeaturedPartner
    ...PageBodyPartnerCategory
    ...PageBodyPartnerHero
    ...PageBodyAdPromo
    ...PageBodyFeaturedVideo
    ...PageBodyFeaturedLink
    ...PageBodyPartnerLinks
    ...PageBodyOfferPromo
    ...PageBodyChildTabs
    ...PageBodyVideoSlider
    ...PageBodyStats
    ...PageBodyVideoDetails
    ...PageBodyHtmlEmbed
    ...PageBodyImageWithCopy

    # The following slices do not have fragments:
    #...PageBodyFooter
    #...PageBodyHeader
  }
`

export const slicesMap = reshapeSlicesMap(slices)
