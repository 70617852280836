import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'
import { Icon } from '../components/Icon'

export type PageBodyFeaturedVideoProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyFeaturedVideo = ({
  heading,
  videoTitle,
  posterFluid,
  posterAlt,
  linkToVideo
}: PageBodyFeaturedVideoProps) => {
  return posterFluid && linkToVideo ? (
    <Link href={linkToVideo} className="featured-video">
      <div className="featured-video__item">
        {heading && (
          <HTMLContent html={heading} />
        )}

        <div className="featured-video__item-wrapper">
          <div className="featured-video__item__video">
            <div className="image-cover featured-video__media">
              <GatsbyImage fluid={posterFluid} alt={posterAlt}/>

              <div
                className="play-button"
              >
                <Icon name="play" />
              </div>
            </div>
          </div>
        </div>

        {linkToVideo && (
          <div className="featured-video__item__title">
            {videoTitle && (
              <HTMLContent html={videoTitle} />
            )}
          </div>
        )}
      </div>
    </Link>
  ) : null
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyFeaturedVideoFragment, typeof mapDataToContext>) => ({
  heading: getRichText(data?.primary?.featured_video_section_heading),
  posterFluid: data?.primary?.featured_video_cover_image?.fluid,
  posterAlt: data?.primary?.featured_video_cover_image?.alt,
  videoTitle: getRichText(data?.primary?.featured_video_title),
  linkToVideo: data?.primary?.link_to_video?.url
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyFeaturedVideo on PrismicPageBodyFeaturedVideo {
    primary {
      featured_video_section_heading {
        text
        html
      }
      featured_video_cover_image {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
      featured_video_title {
        text
        html
      }
      link_to_video {
        url
        target
      }
    }
  }
`

export default PageBodyFeaturedVideo
