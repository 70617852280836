import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'

export type PageBodyFeaturedPartnerProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyFeaturedPartner = ({
  excerpt,
  href,
  partnerLogoFluid,
  partnerLogoAlt,
  imageFluid,
  imageAlt
}: PageBodyFeaturedPartnerProps) => href && imageFluid ? (
  <Link href={href} className="featured-partner">
    {partnerLogoFluid && (
      <div className="featured-partner__logo">
        <GatsbyImage fluid={partnerLogoFluid} alt={partnerLogoAlt} />
      </div>
    )}

    <div className="featured-partner__image">
      <GatsbyImage fluid={imageFluid} alt={imageAlt} />
    </div>

    <HTMLContent html={excerpt} />
  </Link>
) : null

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyFeaturedPartnerFragment, typeof mapDataToContext>) => ({
  excerpt: getRichText(data?.primary?.excerpt),
  href: data?.primary?.link?.url,
  partnerLogoFluid: data?.primary?.partner_logo?.fluid,
  partnerLogoAlt: data?.primary?.partner_logo?.alt,
  imageFluid: data?.primary?.image?.fluid,
  imageAlt: data?.primary?.image?.alt
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyFeaturedPartner on PrismicPageBodyFeaturedPartner {
    primary {
      excerpt {
        text
        html
      }
      link {
        url
        target
      }
      partner_logo {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
      image {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyFeaturedPartner