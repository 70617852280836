import * as React from 'react'
import { graphql } from 'gatsby'
import Airtable from 'airtable'

import { getRichText } from '@walltowall/helpers'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'

import whySquare from '../assets/why-i-run-square.png'
import whyVertical from '../assets/why-i-run-vertical.png'

export type PageBodyWhyIRunWallProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyWhyIRunWall = ({
  description,
  downloadInstructions
}: PageBodyWhyIRunWallProps) => {
  const base = new Airtable({ apiKey: process.env.GATSBY_AIRTABLE_API_KEY }).base(process.env.GATSBY_AIRTABLE_BASE_ID)

  const [activePage, setActivePage] = React.useState('edit')
  const [message, setMessage] = React.useState('')
  const [savedMessage, setSavedMessage] = React.useState('')
  const [lastReason, setLastReason] = React.useState(null)
  const [lastUploadedDate, setLastUploadedDate] = React.useState(new Date().toISOString())
  const [reasons, setReasons] = React.useState([])
  const [showLoadMore, setShowLoadMore] = React.useState(false)
  const [currentId, setCurrentId] = React.useState(null)

  const canvas = React.useRef(null)
  const verticalCanvas = React.useRef(null)

  const amountPerPage = 30

  React.useEffect(() => {
    base('Why I Run Submissions').select({
      view: 'Approval Queue',
      filterByFormula: `IF({Approval} = 'Show on Website', 1, 0)`,
      sort: [
        {
          field: 'Date Created',
          direction: 'asc'
        }
      ],
      pageSize: 1
    }).firstPage((err, records) => {
      if (err) {
        console.error(err)

        return
      }

      setLastReason(records[0])
      loadNextPage(records[0])
    })
  }, [])


  const loadNextPage = (initialLast) => {
    let updatedReasons = []

    base('Why I Run Submissions').select({
      view: 'Approval Queue',
      filterByFormula: `AND(IF({Approval} = 'Show on Website', 1, 0), IS_BEFORE({Date Created}, '${lastUploadedDate}'))`,
      sort: [
        {
          field: 'Date Created',
          direction: 'desc'
        }
      ],
      pageSize: amountPerPage
    }).eachPage((records) => {
      updatedReasons = reasons.concat(records)
      setReasons(updatedReasons)

      const lastRecord = updatedReasons[updatedReasons.length - 1]

      if (lastRecord) {
        setLastUploadedDate(lastRecord.get('Date Created'))

        if (lastReason) {
          if (lastRecord.id === lastReason.id) {
            setShowLoadMore(false)
          } else {
            setShowLoadMore(true)
          }
        } else if (initialLast) {
          if (lastRecord.id === initialLast.id) {
            setShowLoadMore(false)
          } else {
            setShowLoadMore(true)
          }
        } else {
          setShowLoadMore(true)
        }
      }
    }, (err) => {
      setShowLoadMore(false)

      if (err) {
        console.error(err)

        return
      }
    })
  }

  const saveReason = () => {
    // TODO: profanity filter
    if (message) {
      if (currentId) {
        base('Why I Run Submissions').update([
          {
            "id": currentId,
            "fields": {
              "Reason": message
            }
          }
        ], function(err, records) {
          if (err) {
            console.error(err)

            return
          }

          setSavedMessage(message)
        })
      } else {
        base('Why I Run Submissions').create([
          {
            "fields": {
              "Reason": message,
            }
          }
        ], function(err, records) {
          if (err) {
            console.error(err)

            return
          }

          setSavedMessage(message)
          setCurrentId(records[0].getId())
        })
      }
    }
  }

  const renderCanvas = (
    item: React.MutableRefObject<null>,
    width: number,
    height: number
  ) => {
    setActivePage('share')

    const c = item?.current?.getContext('2d')

    if (c) {
      c.textAlign = 'center'

      const bg = new Image()

      if (height > width) {
        bg.src = whyVertical
      } else {
        bg.src = whySquare
      }

      c.drawImage(bg, 0, 0, width, height)

      c.font = 'bold 72pt soleil'
      c.fillStyle = '#000'

      const maxWidth = 840
      const lineHeight = 100
      const x = 520
      const y = height > width ? 1025 : 650

      // display wrapped text on canvas
      wrapText(c, message, x, y, maxWidth, lineHeight)

      c.fillStyle = '#e87270'
      c.font = 'bold 45pt soleil'

      const whyY = height > width ? 760 : 410
      c.fillText('WHY I MOVE:', x, whyY)
    }
  }

  const wrapText = (
    context: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number
  ) => {
    const words = text.split(' ')
    let line = ''
    const lines = []

    // show manual line breaks on new lines
    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' '
      const metrics = context.measureText(testLine)
      const testWidth = metrics.width

      if (testWidth > maxWidth && n > 0) {
        lines.push(line.trim())
        line = words[n] + ' '
      } else {
        line = testLine
      }
    }

    lines.push(line.trim())

    // calculate vertical positioning of each line of text
    for(let i = 0; i < lines.length; i++) {
      const start = -(lineHeight / 2) + ((lineHeight / 2) * i)
      const linesAfter = lines.length - (i + 1)
      const verticalDifference = start - ((lineHeight / 2) * linesAfter)

      const lineY = y + verticalDifference

      context.fillText(lines[i], x, lineY)
    }
  }

  const download = (item: React.MutableRefObject<null>) => {
    const image = item?.current?.toDataURL('image/png', 1.0)

    if (image) {
      const a = document.createElement('a')
      a.href = image
      a.download = 'why-i-run'

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(image)
          a.removeEventListener('click', clickHandler)
        }, 150)
      }

      a.addEventListener('click', clickHandler, false)
      a.click()
    }
  }

  return (
    <div className="why-run">
      <div className={`why-run__edit ${activePage === 'edit' ? 'show' : ''}`}>
        <span
          className="cheer-card__vertical-background"
          style={{
            backgroundImage: `url('${whySquare}')`
          }}
        ></span>

        <span
          className="cheer-card__vertical-background"
          style={{
            backgroundImage: `url('${whyVertical}')`
          }}
        ></span>

        <HTMLContent html={description} />

        <textarea
          maxLength={30}
          placeholder="Enter your reason here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        >
        </textarea>

        <div className="why-run__next">
          <button
            className="button"
            onClick={() => {
              renderCanvas(canvas, 1080, 1080)
              renderCanvas(verticalCanvas, 1080, 1920)
            }}
            disabled={!message}
          >
            Next
          </button>
        </div>

        <ul className="reasons">
          {reasons.map((reason, index: number) => reason?.get('Reason') && (
            <li key={index}>{reason.get('Reason')}</li>
          ))}
        </ul>

        {showLoadMore && (
          <div className="reasons__load-more">
            <button
              type="button"
              className="link link--large"
              onClick={loadNextPage}
            >
              Load More
            </button>
          </div>
        )}
      </div>

      <div className={`cheer-card__share why-run__share ${activePage === 'share' ? 'show' : ''}`}>
        <div className="back-button">
          <button
            className="link"
            onClick={() => setActivePage('edit')}
          >
            Back to Edit
          </button>
        </div>

        <div className="cheer-card__preview">
          <canvas width="1080" height="1080" ref={canvas}></canvas>
        </div>

        <div className="cheer-card__preview cheer-card__preview--vertical">
          <canvas width="1080" height="1920" ref={verticalCanvas}></canvas>
        </div>

        <div className="why-run__add">
          <button
            className="button"
            onClick={() => {
              saveReason()
            }}
            disabled={savedMessage === message}
          >
            Add to the Wall
          </button>
        </div>

        <div className="cheer-card__right">
          <h3>Downloads</h3>
          <HTMLContent html={downloadInstructions} />

          <div className="cheer-card__downloads">
            <button className="link link--large" onClick={() => download(canvas)}>
              Square
            </button>

            <button className="link link--large" onClick={() => download(verticalCanvas)}>
              Vertical
            </button>
          </div>
        </div>

        <h3>The Wall</h3>
        <ul className="reasons">
          {savedMessage && (
            <li>{savedMessage}</li>
          )}
          {reasons.map((reason, index: number) => reason?.get('Reason') && (
            <li key={index}>{reason.get('Reason')}</li>
          ))}
        </ul>

        {showLoadMore && (
          <div className="reasons__load-more">
            <button
              type="button"
              className="link link--large"
              onClick={loadNextPage}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export const mapDataToProps = ({
  data
}: MapDataToPropsArgs<PageBodyWhyIRunWallFragment, typeof mapDataToContext>) => ({
  description: getRichText(data?.primary?.description),
  downloadInstructions: getRichText(data?.primary?.download_instructions),
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyWhyIRunWall on PrismicPageBodyWhyIRunWall {
    primary {
      description {
        text
        html
      }
      download_instructions {
        text
        html
      }
    }
  }
`

export default PageBodyWhyIRunWall
