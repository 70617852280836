import * as React from 'react'
import clsx from 'clsx'

import { ReactComponent as AssetIconFacebookCircle } from '../assets/icon-facebook-circle.svg'
import { ReactComponent as AssetIconTwitterCircle } from '../assets/icon-twitter-circle.svg'
import { ReactComponent as AssetIconInstagramCircle } from '../assets/icon-instagram-circle.svg'
import { ReactComponent as AssetIconHome } from '../assets/icon-home.svg'
import { ReactComponent as AssetIconRaceDay } from '../assets/icon-race-day.svg'
import { ReactComponent as AssetIconExpo } from '../assets/icon-expo.svg'
import { ReactComponent as AssetIconVideos } from '../assets/icon-videos.svg'
import { ReactComponent as AssetIconMore } from '../assets/icon-more.svg'
import { ReactComponent as AssetLogo } from '../assets/logo.svg'
import { ReactComponent as AssetPresentedByBrooks } from '../assets/presented-by-brooks.svg'
import { ReactComponent as AssetGreenCheck } from '../assets/icon-green-check.svg'
import { ReactComponent as AssetPlay } from '../assets/icon-play.svg'
import { ReactComponent as AssetP3R } from '../assets/icon-p3r.svg'
import { ReactComponent as AssetClose } from '../assets/icon-close.svg'
import { ReactComponent as AssetBack } from '../assets/icon-back.svg'

const icons = {
  back: AssetBack,
  close: AssetClose,
  expo: AssetIconExpo,
  home: AssetIconHome,
  facebookCircle: AssetIconFacebookCircle,
  greenCheck: AssetGreenCheck,
  instagramCircle: AssetIconInstagramCircle,
  logo: AssetLogo,
  more: AssetIconMore,
  p3r: AssetP3R,
  play: AssetPlay,
  presentedByBrooks: AssetPresentedByBrooks,
  raceDay: AssetIconRaceDay,
  twitterCircle: AssetIconTwitterCircle,
  videos: AssetIconVideos,
} as const

export type IconProps = {
  name: keyof typeof icons
  displayClassName?: string
} & React.SVGProps<SVGSVGElement>

export const Icon = ({
  name,
  displayClassName = 'block',
  className,
  ...restProps
}: IconProps) => {
  const SVG = icons[name]

  return <SVG {...restProps} className={clsx(displayClassName, className)} />
}
