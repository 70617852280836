import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { getRichText } from '@walltowall/helpers'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'
import Slider from 'react-slick'

export type PageBodyPartnerCategoryProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  variableWidth: true
}

const MoveDragThreshold = 10;

function useDragDetection(): {
  handleMouseDown: () => void
  dragging: boolean
} {
  const [mouseDown, setMouseDown] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);

  React.useEffect(() => {
    let mouseMove = 0;

    function handleMouseUp(): void {
      setMouseDown(false);
    }

    function handleMouseMove(e: MouseEvent): void {
      mouseMove += Math.abs(e.movementX) + Math.abs(e.movementY);
      setDragging(mouseMove > MoveDragThreshold);
    }

    if (mouseDown) {
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [mouseDown]);

  function handleMouseDown(): void {
    setMouseDown(true);
    setDragging(false);
  }

  return {
    handleMouseDown,
    dragging,
  };
}

const PageBodyPartnerCategory = ({
  category,
  children
}: PageBodyPartnerCategoryProps) => (
  <div
    className="toolkit partner-category"
  >
    <HTMLContent html={category} />

    <div className="toolkit__list partner-category__list">
      <Slider
        {...settings}
      >
        {children}
      </Slider>
    </div>
  </div>
)

type CardProps = {
  partnerLogoFluid?: FluidObject
  partnerLogoAlt?: string
  excerpt?: string
  href?: string
}

const Card = ({
  partnerLogoFluid,
  partnerLogoAlt,
  excerpt,
  href
}: CardProps) => {
  const {
    handleMouseDown,
    dragging,
  } = useDragDetection();

  function handleChildClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    if (dragging) {
      e.preventDefault();
    }
  }

  return excerpt && href ? (
    <div>
      <Link
        className="partner-card"
        href={href}
        onMouseDownCapture={handleMouseDown}
        onClickCapture={handleChildClick}
      >
        {partnerLogoFluid && (
          <div className="partner-card__logo">
            <GatsbyImage
              fluid={partnerLogoFluid}
              alt={partnerLogoAlt}
            />
          </div>
        )}

        <HTMLContent html={excerpt} />
      </Link>
    </div>
  ) : null
}

PageBodyPartnerCategory.Card = Card

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyPartnerCategoryFragment, typeof mapDataToContext>) => ({
  category: getRichText(data?.primary?.category),
  children: data?.items?.map((item) => (
    <PageBodyPartnerCategory.Card
      key={item?.link?.url}
      partnerLogoFluid={item?.partner_logo?.fluid}
      partnerLogoAlt={item?.partner_logo?.alt}
      excerpt={getRichText(item?.excerpt)}
      href={item?.link?.url}
    />
  )) as React.ReactNode
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyPartnerCategory on PrismicPageBodyPartnerCategory {
    primary {
      category {
        text
        html
      }
    }
    items {
      excerpt {
        text
        html
      }
      partner_logo {
        alt
        fluid(maxWidth: 200) {
          ...GatsbyPrismicImageFluid
        }
      }
      link {
        url
        target
      }
    }
  }
`

export default PageBodyPartnerCategory