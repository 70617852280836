import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'
import { Icon } from '../components/Icon'

export type PageBodyOfferPromoProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyOfferPromo = ({
  offerTitle,
  offerDescription,
  partnerLogoFluid,
  partnerLogoAlt,
  offerImageFluid,
  offerImageAlt,
  promoCode,
  finePrint,
  href,
  target,
  parentHref
}: PageBodyOfferPromoProps) => (
  <div
    className="partner-hero offer-promo"
  >
    {partnerLogoFluid && (
      <div className="partner-hero__logo offer-promo__logo">
        {parentHref && (
          <Link href={parentHref}>
            <Icon name="back" />
          </Link>
        )}

        <GatsbyImage fluid={partnerLogoFluid} alt={partnerLogoAlt} />
      </div>
    )}

    <div className="offer-promo__heading">
      <HTMLContent html={offerTitle} />
      <HTMLContent html={offerDescription} />
    </div>

    {offerImageFluid ? (
      <div className="offer-promo__image">
        <GatsbyImage fluid={offerImageFluid} alt={offerImageAlt} />
      </div>
    ) : promoCode ? (
      <div className="offer-promo__code">
        <p>{ promoCode }</p>
      </div>
    ): null}

    {finePrint && (
      <div className="offer-promo__fine-print">
        <HTMLContent html={finePrint} />
      </div>
    )}

    {href && (
      <div className="offer-promo__link">
        <Link className="link" href={href} target={target}>
          Visit Website
        </Link>
      </div>
    )}
  </div>
)

export const mapDataToProps = ({
  data,
  meta,
}: MapDataToPropsArgs<
  PageBodyOfferPromoFragment,
  typeof mapDataToContext
>) => ({
  parentPageHref: meta?.rootData?.prismicPage?.data?.parent?.document?.url,
  offerTitle: getRichText(data?.primary?.offer_title),
  offerDescription: getRichText(data?.primary?.offer_description),
  partnerLogoFluid: data?.primary?.partner_logo?.fluid,
  partnerLogoAlt: data?.primary?.partner_logo?.alt,
  offerImageFluid: data?.primary?.offer_image_qr?.fluid,
  offerImageAlt: data?.primary?.offer_image_qr?.alt,
  promoCode: data?.primary?.promo_code,
  finePrint: getRichText(data?.primary?.fine_print),
  href: data?.primary?.link?.url,
  target: data?.primary?.link?.target,
  parentHref: meta?.rootData?.prismicPage?.data?.parent?.document?.url
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyOfferPromo on PrismicPageBodyOfferPromo {
    primary {
      partner_logo {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
      offer_title {
        text
        html
      }
      offer_description {
        text
        html
      }
      offer_image_qr {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
      promo_code
      link {
        url
        target
      }
      fine_print {
        text
        html
      }
    }
  }
`

export default PageBodyOfferPromo