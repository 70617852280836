import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'

export type PageBodyImageWithCopyProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyImageWithCopy = ({
  copy,
  imageFluid,
  imageAlt,
  iconFluid,
  iconAlt,
  linkText,
  href,
  target
}: PageBodyImageWithCopyProps) => imageFluid && (
  <div className="image-with-copy">
    <div className="image-with-copy__image">
      <GatsbyImage fluid={imageFluid} alt={imageAlt} />
    </div>

    <div className="image-with-copy__right">
      {copy && (
        <div className="image-with-copy__copy">
          <HTMLContent html={copy} />
        </div>
      )}

      {href && (
        <div className="image-with-copy__link">
          {iconFluid && (
            <GatsbyImage fluid={iconFluid} alt={iconAlt} />
          )}

          <Link
            className="link link--large"
            href={href}
            target={target}
          >
            {linkText ? linkText : 'Learn More'}
          </Link>
        </div>
      )}
    </div>
  </div>
)

export const mapDataToProps = ({
  data
}: MapDataToPropsArgs<
  PageBodyImageWithCopyFragment,
  typeof mapDataToContext
>) => ({
  copy: getRichText(data?.primary?.copy),
  imageFluid: data?.primary?.image?.fluid,
  imageAlt: data?.primary?.image?.alt,
  iconFluid: data?.primary?.link_icon?.fluid,
  iconAlt: data?.primary?.link_icon?.alt,
  linkText: data?.primary?.promo_code,
  href: data?.primary?.link?.url,
  target: data?.primary?.link?.target,
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyImageWithCopy on PrismicPageBodyImageWithCopy {
    primary {
      image {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
      link_icon {
        alt
        fluid(maxWidth: 100) {
          ...GatsbyPrismicImageFluid
        }
      }
      copy {
        text
        html
      }
      link_text
      link {
        url
        target
      }
    }
  }
`

export default PageBodyImageWithCopy