import * as React from 'react'
import { graphql } from 'gatsby'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

export type PageBodyStatsProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyStats = ({
  children,
  childrenCount
}: PageBodyStatsProps) => {
  const statsRef = React.useRef(null)

  React.useEffect(() => {
    if (statsRef?.current) {
      const activeStat = (Math.floor(Math.random() * childrenCount) + 1)

      statsRef?.current?.querySelector(`.stat:nth-child(${activeStat})`).classList.add('show')
    }
  }, [])

  return (
    <div className="stats" ref={statsRef}>
      {children}
    </div>
  )
}

type StatProps = {
  stat?: string
  line1?: string
  line2?: string
}

const Stat = ({
  stat,
  line1,
  line2
}: StatProps) => stat && line1 ? (
  <div className="stat">
    <h2>
      { stat }
      <span>{ line1 }</span>
    </h2>

    {line2 && (
      <h3>{ line2 }</h3>
    )}

    <div className="stat__left-border"></div>
    <div className="stat__right-border"></div>
  </div>
) : null

PageBodyStats.Stat = Stat

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyStatsFragment, typeof mapDataToContext>) => ({
  childrenCount: data?.items?.length,
  children: data?.items?.map((item, index: number) => (
    <PageBodyStats.Stat
      key={index}
      stat={item?.statistic}
      line1={item?.description_line_1}
      line2={item?.description_line_2}
    />
  )) as React.ReactNode
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyStats on PrismicPageBodyStats {
    items {
      statistic
      description_line_1
      description_line_2
    }
  }
`

export default PageBodyStats
