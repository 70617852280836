import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'
import moveWhite from '../assets/move-white.png'

export type PageBodyHomeHeroProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyHomeHero = ({
  buttonHeader,
  buttonText,
  buttonHref,
  buttonTarget
}: PageBodyHomeHeroProps) => (
  <div
    className="home-hero"
  >
    <div className="home-hero__tagline">
      <h1>Get ready to <span>move</span></h1>
      <img src={moveWhite} alt="" aria-hidden="true" />
    </div>

    <HTMLContent html={buttonHeader} />

    <Link
      href={buttonHref}
      target={buttonTarget}
      className="button"
    >
      {buttonText || "Learn More"}
    </Link>
  </div>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyHomeHeroFragment, typeof mapDataToContext>) => ({
  buttonHeader: getRichText(data?.primary?.button_header),
  buttonText: data?.primary?.button_text,
  buttonHref: data?.primary?.button_link?.url,
  buttonTarget: data?.primary?.button_link?.target,
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyHomeHero on PrismicPageBodyHomeHero {
    primary {
      button_header {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
    }
  }
`

export default PageBodyHomeHero
