import * as React from 'react'
import { Link } from './Link'
import { Icon } from './Icon'
import { useNavigation } from '../hooks/useNavigation'
import { useSiteSettings } from '../hooks/useSiteSettings'

export type MainMenuProps = {
  show?: boolean
  toggleShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const MainMenu = ({
  show,
  toggleShow
}: MainMenuProps): JSX.Element => {
  const navigation = useNavigation()
  const settings = useSiteSettings()

  return (
    <div className={`main-menu ${show ? 'main-menu--open' : ''}`}>
      {navigation.primary.length > 0 && (
        <nav className="primary">
          <ul>
            {navigation.primary.map((item, index) => (
              <React.Fragment key={index}>
                {item?.primary?.link && (
                  <li>
                    <Link href={item.primary.link.url} target={item.primary.link.target}>
                      {item?.primary?.name}
                    </Link>
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </nav>
      )}

      <div className="main-menu__social">
        {settings.facebookURL && (
          <Link href={settings.facebookURL} target="_blank" rel="noreferrer">
            <Icon name="facebookCircle" />
          </Link>
        )}

        {settings.instagramURL && (
          <Link href={settings.instagramURL} target="_blank" rel="noreferrer">
            <Icon name="instagramCircle" />
          </Link>
        )}

        {settings.twitterURL && (
          <Link href={settings.twitterURL} target="_blank" rel="noreferrer">
            <Icon name="twitterCircle" />
          </Link>
        )}

        <a className="p3r-logo" href="https://p3r.org" target="_blank" rel="noreferrer">
          <Icon name="p3r" />
        </a>
      </div>

      <div className="main-menu__copyright">
        {navigation.secondary.length > 0 && (
          <nav className="secondary">
            <ul>
              {navigation.secondary.map((item, index) => (
                <React.Fragment key={index}>
                  {item?.primary?.link && (
                    <li>
                      <Link href={item.primary.link.url} target={item?.primary?.link.target}>
                        {item?.primary?.name}
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </nav>
        )}

        <p>&copy;{new Date().getFullYear()} {settings.siteCopyright}</p>
      </div>

      <button
        className="main-menu__close"
        onClick={() => toggleShow(!show)}
      >
        <Icon name="close" />
      </button>
    </div>
  )
}
