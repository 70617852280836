import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'

export type PageBodyHtmlEmbedProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyHtmlEmbed = ({
  heading,
  embed
}: PageBodyHtmlEmbedProps) => embed ? (
  <div className="html-embed">
    <div className="html-embed__heading">
      <HTMLContent html={heading} />
    </div>

    <HTMLContent html={embed?.text} />
  </div>
) : null

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyHtmlEmbedFragment, typeof mapDataToContext>) => ({
  heading: getRichText(data?.primary?.heading),
  embed: data?.primary?.embed
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyHtmlEmbed on PrismicPageBodyHtmlEmbed {
    primary {
      heading {
        text
        html
      }
      embed {
        text
        html
      }
    }
  }
`

export default PageBodyHtmlEmbed