import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import { DevPanel } from './DevPanel'
import { FinishedButton } from './FinishedButton'
import { Icon } from './Icon'

import { useSiteSettings } from '../hooks/useSiteSettings'

export type LayoutProps = {
  children?: React.ReactNode
  location: Location
}

export const Layout = ({ children, location }: LayoutProps) => {
  const settings = useSiteSettings()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{settings.siteName}</title>
        <meta name="description" content={settings.siteDescription} />
        <link rel="stylesheet" href="https://use.typekit.net/ozb1zuo.css"></link>
        <script async defer src="https://static.cdn.prismic.io/prismic.js?new=true&repo=marathonexperience"></script>
        <link rel="stylesheet" href="https://use.typekit.net/utg0lmm.css"></link>
      </Helmet>

      <main className={
        [
          '/pledge-to-run',
          '/pledge-to-run/'
        ].includes((location || {}).pathname)
        ? 'blue'
        : [
            '/cheer-cards',
            '/cheer-cards/'
          ].includes((location || {}).pathname)
        ? 'green'
        : [
            '/why-i-run',
            '/why-i-run/'
          ].includes((location || {}).pathname)
        ? 'purple'
        : [
            '/race-certificate',
            '/race-certificate/'
          ].includes((location || {}).pathname)
        ? 'certificate'
        : ''
      }>
        {children}

        {![
          '/race-certificate',
          '/race-certificate/',
          '/cheer-cards',
          '/cheer-cards/',
          '/race-day',
          '/race-day/',
          '/pledge-to-run',
          '/pledge-to-run/',
          '/why-i-run',
          '/why-i-run/'
        ].includes((location || {}).pathname) && (
          <FinishedButton className="finished-button" href="/race-certificate" />
        )}

        <div className="page-footer">
          <Icon name="p3r" />

          <p>{settings.siteDescription}</p>
        </div>
      </main>

      {process.env.NODE_ENV === 'development' && <DevPanel />}
    </>
  )
}
