import * as React from 'react'

import { PageTemplateEnhancerProps } from '../templates/page'

import { Link } from '../components/Link'
import { Icon } from '../components/Icon'
import { MainMenu } from '../components/MainMenu'

import ribbon from '../assets/ribbon.png'

export type PageBodyFooterProps = PageTemplateEnhancerProps

const PageBodyFooter = () => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <>
      <MainMenu
        show={menuOpen}
        toggleShow={setMenuOpen}
      />

      <footer>
        <Link href="/">
          <span>
            <Icon name="home" />
          </span>

          Home
        </Link>

        <Link
          href="/race-day"
        >
          <span>
            <Icon name="raceDay" />
          </span>

          Race Day
        </Link>

        <Link
          href="/expo"
        >
          <span>
            <Icon name="expo" />
          </span>

          Expo
        </Link>

        <Link
          href="/videos"
        >
          <span>
            <Icon name="videos" />
          </span>

          Videos
        </Link>

        <button onClick={() => setMenuOpen(!menuOpen)}>
          <span>
            <Icon name="more" />
          </span>

          More
        </button>

        <Link
          className="menu-race"
          href="/race-certificate"
        >
          <span>
            <img src={ribbon} alt="race ribbon"/>
          </span>

          Log Run
        </Link>
      </footer>
    </>
  )
}

export const mapDataToContext = () => ({})

export default PageBodyFooter
