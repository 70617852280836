import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'

export type PageBodyFeaturedLinkProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyFeaturedLink = ({
  heading,
  linkTitle,
  linkExcerpt,
  linkHref,
  linkTarget,
  linkText,
  linkImageFluid,
  linkImageAlt
}: PageBodyFeaturedLinkProps) => linkImageFluid && linkHref ? (
  <div className="featured-link">
    <HTMLContent html={heading} />

    <div className="featured-link__card">
      <GatsbyImage fluid={linkImageFluid} alt={linkImageAlt}/>

      <div className="featured-link__card__copy">
        <HTMLContent html={linkTitle} />
        <HTMLContent html={linkExcerpt} />
      </div>
    </div>

    <Link className="link" href={linkHref} target={linkTarget}>
      {linkText ? linkText : 'Learn More'}
    </Link>
  </div>
) : null

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyFeaturedLinkFragment, typeof mapDataToContext>) => ({
  heading: getRichText(data?.primary?.heading),
  linkTitle: getRichText(data?.primary?.link_title),
  linkExcerpt: getRichText(data?.primary?.link_excerpt),
  linkHref: data?.primary?.link?.url,
  linkTarget: data?.primary?.link?.target,
  linkText: data?.primary?.link_text,
  linkImageFluid: data?.primary?.link_image?.fluid,
  linkImageAlt: data?.primary?.link_image?.alt
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyFeaturedLink on PrismicPageBodyFeaturedLink {
    primary {
      heading {
        text
        html
      }
      link_title {
        text
        html
      }
      link_excerpt {
        text
        html
      }
      link {
        url
        target
      }
      link_text
      link_image {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyFeaturedLink
