import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import HTMLRenderer, { HTMLRendererProps } from 'react-html-renderer'
import clsx from 'clsx'

import { Link } from './Link'
import { Text } from './Text'

const defaultElement = 'div'

/**
 * Custom styles can be added to Prismic Rich Text fields using labels. Styles
 * defined here will be applied using the `span` component defined in
 * `components` below.
 *
 * @see https://user-guides.prismic.io/en/articles/1943308-add-custom-styles-to-rich-text
 */
const labelClassNames: Record<string, string> = {}

const components: React.ComponentProps<typeof HTMLRenderer>['components'] = {
  h1: (props) => (
    <Text
      as="h1"
      {...props}
      className={clsx(
        props.className,
        props.class,
      )}
    />
  ),
  h2: (props) => (
    <Text
      as="h2"
      {...props}
      className={clsx(
        props.className,
        props.class,
      )}
    />
  ),
  h3: (props) => (
    <Text
      as="h3"
      {...props}
      className={clsx(
        props.className,
        props.class,
      )}
    />
  ),
  h4: (props) => (
    <Text
      as="h4"
      {...props}
      className={clsx(
        props.className,
        props.class,
      )}
    />
  ),
  h5: (props) => (
    <Text
      as="h5"
      {...props}
      className={clsx(
        props.className,
        props.class,
      )}
    />
  ),
  h6: (props) => (
    <Text
      as="h6"
      {...props}
      className={clsx(
        props.className,
        props.class,
      )}
    />
  ),
  p: (props) => (
    <Text
      as="p"
      {...props}
      className={clsx(props.className, props.class)}
    />
  ),
  ul: (props) => (
    <ul
      {...props}
      className={clsx(
        props.className,
        props.class,
      )}
    />
  ),
  ol: (props) => (
    <ol
      {...props}
      className={clsx(
        props.className,
        props.class,
      )}
    />
  ),
  li: (props) => (
    <Text
      as="li"
      {...props}
      className={clsx(props.className, props.class)}
    />
  ),
  a: ({ href, ...props }) => (
    <Link
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      href={href!}
      {...props}
    />
  ),
  strong: (props) => (
    <strong
      {...props}
      className={clsx(props.className, props.class)}
    />
  ),
  span: ({ class: labelName, ...props }) => (
    <span
      {...props}
      className={clsx(labelClassNames[labelName], props.className)}
    />
  ),
}

type HTMLContentOwnProps = {
  className?: string
  html?: HTMLRendererProps['html']
  componentOverrides?: HTMLRendererProps['componentOverrides']
}

export type HTMLContentProps<
  T extends React.ElementType = typeof defaultElement
> = PolymorphicPropsWithoutRef<HTMLContentOwnProps, T>

export const HTMLContent = <
  E extends React.ElementType = typeof defaultElement
>({
  as,
  className,
  html,
  componentOverrides,
  ...props
}: HTMLContentProps<E>) => {
  const Element: React.ElementType = as ?? defaultElement

  return (
    <Element className={className} {...props}>
      <HTMLRenderer
        html={html}
        components={components}
        componentOverrides={componentOverrides}
      />
    </Element>
  )
}
