import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import confetti from 'canvas-confetti'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'


import { HTMLContent } from '../components/HTMLContent'

import logo3d from '../assets/logo-3d.png'
import bellInactive from '../assets/bell-inactive.png'
import bellActive from '../assets/bell-active.png'
import cert5kSquare from '../assets/certificate-5k-square.png'
import cert5kVertical from '../assets/certificate-5k-vertical.png'
import certHalfSquare from '../assets/certificate-half-square.png'
import certHalfVertical from '../assets/certificate-half-vertical.png'
import certKidsSquare from '../assets/certificate-kids-square.png'
import certKidsVertical from '../assets/certificate-kids-vertical.png'
import certMarathonSquare from '../assets/certificate-marathon-square.png'
import certMarathonVertical from '../assets/certificate-marathon-vertical.png'
import certPetSquare from '../assets/certificate-pet-square.png'
import certPetVertical from '../assets/certificate-pet-vertical.png'
import certRelaySquare from '../assets/certificate-relay-square.png'
import certRelayVertical from '../assets/certificate-relay-vertical.png'
import certToddlerSquare from '../assets/certificate-toddler-square.png'
import certToddlerVertical from '../assets/certificate-toddler-vertical.png'
import certPrSquare from '../assets/certificate-pr-square.jpg'
import certPrVertical from '../assets/certificate-pr-vertical.jpg'

export type PageBodyRaceCertificateProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyRaceCertificate = ({
  formMessage,
  bellInstructions,
  certificateHeading,
  downloadInstructions
}: PageBodyRaceCertificateProps) => {
  const [activePage, setActivePage] = React.useState('form')
  const [name, setName] = React.useState('')
  const [race, setRace] = React.useState('marathon')
  const [hours, setHours] = React.useState('')
  const [minutes, setMinutes] = React.useState('')
  const [seconds, setSeconds] = React.useState('')
  const [bellClicked, setBellClicked] = React.useState(false)
  const [record, setRecord] = React.useState(false)

  const canvas = React.useRef(null)
  const verticalCanvas = React.useRef(null)
  const confettiCanvas = React.useRef(null)

  React.useEffect(() => {
    if (confettiCanvas.current && activePage === 'share') {
      confettiCanvas.current.confetti = confettiCanvas.current.confetti || confetti.create(confettiCanvas.current, { resize: true });

      const colors = ['#f6ba50', '#eda93d', '#cd7a23', '#f7ceb4']

      confettiCanvas.current.confetti({
        particleCount: 200,
        spread: 70,
        origin: { y: 1 },
        scalar: 1.6,
        startVelocity: 70,
        colors: colors
      });
    }
  }, [activePage])

  const renderCanvas = (
    item: React.MutableRefObject<null>,
    width: number,
    height: number,
    hasRecord: boolean
  ) => {
    window.scrollTo(0, 0)
    setActivePage('share')
    const c = item?.current?.getContext('2d')

    if (c) {
      c.clearRect(0, 0, width, height)
      c.textAlign = 'center'

      const bg = new Image()

      if (hasRecord) {
        if (height > width) {
          bg.src = certPrVertical
        } else {
          bg.src = certPrSquare
        }
      } else if (race === 'marathon') {
        if (height > width) {
          bg.src = certMarathonVertical
        } else {
          bg.src = certMarathonSquare
        }
      } else if (race === 'half') {
        if (height > width) {
          bg.src = certHalfVertical
        } else {
          bg.src = certHalfSquare
        }
      } else if (race === 'relay') {
        if (height > width) {
          bg.src = certRelayVertical
        } else {
          bg.src = certRelaySquare
        }
      } else if (race === '5k') {
        if (height > width) {
          bg.src = cert5kVertical
        } else {
          bg.src = cert5kSquare
        }
      } else if (race === 'kids') {
        if (height > width) {
          bg.src = certKidsVertical
        } else {
          bg.src = certKidsSquare
        }
      } else if (race === 'toddler') {
        if (height > width) {
          bg.src = certToddlerVertical
        } else {
          bg.src = certToddlerSquare
        }
      } else if (race === 'pet') {
        if (height > width) {
          bg.src = certPetVertical
        } else {
          bg.src = certPetSquare
        }
      }

      c.drawImage(bg, 0, 0, width, height)

      // name config
      let nameFill = '#000'
      const nameX = 545
      let nameY = height > width ? 785 : 435

      // time config
      let timeFont = '116pt soleil'
      let timeY = height > width ? 980 : 630
      let hoursX = 290
      const minutesX = 530
      let secondsX = 770

      // label config
      let labelFont = 'bold 45pt soleil'
      let labelY = height > width ? 1055 : 706
      let labelHourX = 292
      let labelMinutesX = 530
      let labelSecondsX = 765

      // line config
      let lineColor = '#ddd'
      let lineYStart = height > width ? 870 : 520
      let lineYEnd = height > width ? 1070 : 720
      let line1X = 410
      let line2X = 650

      // overrides if it's a personal record
      if (hasRecord) {
        nameFill = '#926C10'
        // 915
        nameY = height > width ? 1040 : 565;

        timeFont = '68pt soleil'
        timeY = height > width ? 1185 : 700
        hoursX = 360
        secondsX = 700

        labelFont = 'bold 32pt soleil'
        labelY = height > width ? 1240 : 755
        labelHourX = 360
        labelMinutesX = 529
        labelSecondsX = 697

        lineColor = '#d1a232'
        lineYStart = height > width ? 1120 : 635
        lineYEnd = height > width ? 1270 : 765
        line1X = 445
        line2X = 615
      }

      c.fillStyle = nameFill

      if (name) {
        c.font = 'bold 56pt soleil'

        if (hasRecord) {
          const maxWidth = 600
          const lineHeight = 75
          wrapText(c, name, nameX, nameY, maxWidth, lineHeight)
        } else {
          c.fillText(name, nameX, nameY)
        }
      }

      c.font = timeFont
      c.fillText(timeDisplay(hours), hoursX, timeY)
      c.fillText(timeDisplay(minutes), minutesX, timeY)
      c.fillText(timeDisplay(seconds), secondsX, timeY)

      c.font = labelFont
      c.fillText('HR', labelHourX, labelY)
      c.fillText('MIN', labelMinutesX, labelY)
      c.fillText('SEC', labelSecondsX, labelY)

      c.lineWidth = 4
      c.strokeStyle = lineColor
      c.beginPath()
      c.moveTo(line1X, lineYStart)
      c.lineTo(line1X, lineYEnd)
      c.stroke()

      c.beginPath()
      c.moveTo(line2X, lineYStart)
      c.lineTo(line2X, lineYEnd)
      c.stroke()
    }
  }

  const timeDisplay = (time) => {
    if (time) {
      if (time.length === 1) {
        return `0${time}`
      } else {
        return time
      }
    } else {
      return '00'
    }
  }

  const wrapText = (
    context: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number
  ) => {
    const words = text.split(' ')
    let line = ''
    const lines = []

    // show manual line breaks on new lines
    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' '
      const metrics = context.measureText(testLine)
      const testWidth = metrics.width

      if (testWidth > maxWidth && n > 0) {
        lines.push(line.trim())
        line = words[n] + ' '
      } else {
        line = testLine
      }
    }

    lines.push(line.trim())

    // calculate vertical positioning of each line of text
    for(let i = 0; i < lines.length; i++) {
      const start = -(lineHeight / 2) + ((lineHeight / 2) * i)
      const linesAfter = lines.length - (i + 1)
      const verticalDifference = start - ((lineHeight / 2) * linesAfter)

      const lineY = y + verticalDifference

      context.fillText(lines[i], x, lineY)
    }
  }

  const download = (item: React.MutableRefObject<null>) => {
    const image = item?.current?.toDataURL('image/png', 1.0)

    if (image) {
      const a = document.createElement('a');
      a.href = image;
      a.download = 'race-certificate';

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(image);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };

      a.addEventListener('click', clickHandler, false);
      a.click();
    }
  }

  return (
    <div className="race-certificate">
      <div className={`race-certificate__form ${activePage === 'form' ? 'show' : ''}`}>
        <span
          className="cheer-card__vertical-background"
          style={{
            backgroundImage: race === 'marathon'
              ? `url('${certMarathonSquare}')`
              : race === 'half'
              ? `url('${certHalfSquare}')`
              : race === 'relay'
              ? `url('${certRelaySquare}')`
              : race === '5k'
              ? `url('${cert5kSquare}')`
              : race === 'kids'
              ? `url('${certKidsSquare}')`
              : race === 'toddler'
              ? `url('${certToddlerSquare}')`
              : race === 'pet'
              ? `url('${certPetSquare}')`
              : ''
          }}
        ></span>

        <span
          className="cheer-card__vertical-background"
          style={{
            backgroundImage: race === 'marathon'
              ? `url('${certMarathonVertical}')`
              : race === 'half'
              ? `url('${certHalfVertical}')`
              : race === 'relay'
              ? `url('${certRelayVertical}')`
              : race === '5k'
              ? `url('${cert5kVertical}')`
              : race === 'kids'
              ? `url('${certKidsVertical}')`
              : race === 'toddler'
              ? `url('${certToddlerVertical}')`
              : race === 'pet'
              ? `url('${certPetVertical}')`
              : ''
          }}
        ></span>

        <span
          className="cheer-card__vertical-background"
          style={{
            backgroundImage: `url('${certPrSquare}')`
          }}
        ></span>

        <span
          className="cheer-card__vertical-background"
          style={{
            backgroundImage: `url('${certPrVertical}')`
          }}
        ></span>

        {formMessage && (
          <div className="race-certificate__form__heading">
            <img src={logo3d} alt="3d dick's sporting goods pittsburgh marathon logo" />
            <HTMLContent html={formMessage} />
          </div>
        )}

        <form>
          <label>
            Name
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </label>

          <label className="dropdown">
            Race
            <select value={race} onChange={(e) => setRace(e.target.value)}>
              <option value="marathon">Marathon</option>
              <option value="half">Half Marathon</option>
              <option value="relay">Marathon Relay</option>
              <option value="5k">5k</option>
              <option value="kids">Kids Marathon</option>
              <option value="toddler">Toddler Trot</option>
              <option value="pet">Pet Walk</option>
            </select>
          </label>

          <div className="time-input">
            <label>
              HR
              <input type="number" placeholder="0" min={0} value={hours} onChange={(e) => setHours(e.target.value)} />
            </label>

            <label>
              MIN
              <input type="number" placeholder="0" min={0} max={59} value={minutes} onChange={(e) => setMinutes(e.target.value)} />
            </label>

            <label>
              SEC
              <input type="number" placeholder="0" min={0} max={59} value={seconds} onChange={(e) => setSeconds(e.target.value)} />
            </label>
          </div>

          <div className="personal-record">
            {bellInstructions && (
              <label>
                { bellInstructions }
                <input type="checkbox" checked={bellClicked} onChange={(e) => setBellClicked(e.target.checked)} />
                <span>
                  <img src={bellClicked ? bellActive : bellInactive} alt="" />
                </span>
              </label>
            )}
          </div>
        </form>

        <div className="race-certificate__continue">
          <button
            className="button button--black"
            onClick={() => {
              renderCanvas(canvas, 1080, 1080, record)
              renderCanvas(verticalCanvas, 1080, 1920, record)
            }}
          >
            Get Certificate
          </button>
        </div>
      </div>

      <div className={`race-certificate__share ${activePage === 'share' ? 'show' : ''}`}>
        <canvas className="confetti" ref={confettiCanvas}></canvas>

        <div className="race-certificate__share__inner">
          {certificateHeading && (
            <HTMLContent html={certificateHeading} />
          )}

          {bellClicked && (
            <div className="tabs">
              <button
                className={`tab ${!record ? 'tab--active' : ''}`}
                onClick={() => {
                  setRecord(false)
                  renderCanvas(canvas, 1080, 1080, false)
                  renderCanvas(verticalCanvas, 1080, 1920, false)
                }}
              >
                Race Completed
              </button>

              <button
                className={`tab ${record ? 'tab--active' : ''}`}
                onClick={() => {
                  setRecord(true)
                  renderCanvas(canvas, 1080, 1080, true)
                  renderCanvas(verticalCanvas, 1080, 1920, true)
                }}
              >
                PR Certificate
              </button>
            </div>
          )}

          <div className="cheer-card__share">
            <div className="cheer-card__preview">
              <canvas width="1080" height="1080" ref={canvas}></canvas>
            </div>

            <div className="cheer-card__preview cheer-card__preview--vertical">
              <canvas width="1080" height="1920" ref={verticalCanvas}></canvas>
            </div>

            <div className="cheer-card__right">
              <h3>Downloads</h3>
              <HTMLContent html={downloadInstructions} />

              <div className="pledge__downloads">
                <button className="link link--large" onClick={() => download(canvas)}>
                  Square
                </button>

                <button className="link link--large" onClick={() => download(verticalCanvas)}>
                  Vertical
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const mapDataToProps = ({
  data
}: MapDataToPropsArgs<PageBodyRaceCertificateFragment, typeof mapDataToContext>) => ({
  formMessage: getRichText(data?.primary?.form_message),
  bellInstructions: data?.primary?.bell_instructions,
  certificateHeading: getRichText(data?.primary?.certificate_heading),
  downloadInstructions: getRichText(data?.primary?.download_instructions)
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyRaceCertificate on PrismicPageBodyRaceCertificate {
    primary {
      form_message {
        text
        html
      }
      bell_instructions
      certificate_heading {
        text
        html
      }
      download_instructions {
        text
        html
      }
    }
  }
`

export default PageBodyRaceCertificate
