import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet-async'
import { withPreview } from 'gatsby-source-prismic'
import MapSlicesToComponents from '@walltowall/react-map-slices-to-components'

import { PageTemplateQuery } from '../types.generated'
import { PickPartial } from '../types'
import { MapDataToPropsEnhancerArgs } from '../lib/mapSlicesToComponents'
import { pairsEq } from '../lib/pairsEq'
import { slicesMap } from '../slices/PageBody'

import { Layout } from '../components/Layout'
import { useSiteSettings } from '../hooks/useSiteSettings'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**
 * `listMiddleware` for `react-map-slices-to-components`. Add or modify slices
 * for the page here.
 *
 * @see https://github.com/WalltoWall/react-map-slices-to-components#change-the-list-of-slices
 */
// prettier-ignore
export const slicesMiddleware = <T,>(list: T[]) => [
  { __typename: 'PageBodyHeader', id: 'header' },
  ...list,
  { __typename: 'PageBodyFooter', id: 'footer' },
]

/**
 * `mapDataToPropsEnhancer` for `react-map-slices-to-components`. Props defined
 * here are added to all slices.
 *
 * @see https://github.com/WalltoWall/react-map-slices-to-components#providing-global-enhancers
 */
export const mapDataToPropsEnhancer = (
  props: Record<string, unknown> | undefined,
  {
    context,
    previousContext = {},
    nextContext = {},
    previousType,
    nextType,
  }: MapDataToPropsEnhancerArgs,
) => ({
  id: context,
  previousSharesBg: pairsEq(
    previousContext.bgBottom ?? previousContext.bg,
    context.bgTop ?? context.bg,
  ) as boolean | boolean[],
  previousOverhangs: previousContext.overhangsBottom as boolean | boolean[],
  previousIsHeader: previousType === 'PageBodyHeader',
  nextSharesBg: pairsEq(
    context.bgBottom ?? context.bg,
    nextContext.bgTop ?? nextContext.bg,
  ) as boolean | boolean[],
  nextOverhangs: nextContext.overhangsTop as boolean | boolean[],
  nextIsFooter: nextType === 'PageBodyFooter',
  ...props,
})

/**
 * Props added to all slices by `mapDataToPropsEnhancer` for `PageTemplate`.
 * Intersect this type with a slice's known props to get a complete list of
 * available props.
 *
 * @see https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#intersection-types
 */
export type PageTemplateEnhancerProps = PickPartial<
  ReturnType<typeof mapDataToPropsEnhancer>,
  // Props defined in the enhancer function are marked as optional to remove
  // their necessity when using slices as React components.
  | 'id'
  | 'previousOverhangs'
  | 'previousSharesBg'
  | 'nextOverhangs'
  | 'nextSharesBg'
>

export const PageTemplate = ({
  data,
  location,
}: PageProps<PageTemplateQuery>) => {
  const siteSettings = useSiteSettings()
  const page = data?.prismicPage

  /**
   * Metadata made available in a slice's `mapDataToProps` and
   * `mapDataToContext` functions.
   *
   * @see https://github.com/angeloashmore/react-map-to-components#maptocomponents
   */
  const meta = React.useMemo(
    () => ({
      rootData: data,
      location,
    }),
    [data, location],
  )

  return (
    <Layout location={location}>
      <Helmet>
        <title>
          {page?.data?.meta_title ?? page?.data?.title?.text ?? ''}
          {page?.uid === 'home' ? '' : ` - ${siteSettings.siteName}`}
        </title>
        {page?.data?.meta_description && (
          <meta name="description" content={page?.data?.meta_description} />
        )}
      </Helmet>
      <MapSlicesToComponents
        list={page?.data?.body}
        map={slicesMap}
        meta={meta}
        listMiddleware={slicesMiddleware}
        mapDataToPropsEnhancer={mapDataToPropsEnhancer}
      />
    </Layout>
  )
}

export default withPreview(PageTemplate)

export const query = graphql`
  query PageTemplate($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      ...PrismicPageParentRecursive
      data {
        title {
          text
        }
        meta_title
        meta_description
        parent {
          document {
            ... on PrismicPage {
              url
            }
          }
        }
        body {
          __typename
          ... on Node {
            id
          }
          ...SlicesPageBody
        }
      }
    }
  }
`
