import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'
import Slider from 'react-slick'

export type PageBodyPartnerLinksProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  variableWidth: true
}

const MoveDragThreshold = 10;

function useDragDetection(): {
  handleMouseDown: () => void
  dragging: boolean
} {
  const [mouseDown, setMouseDown] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);

  React.useEffect(() => {
    let mouseMove = 0;

    function handleMouseUp(): void {
      setMouseDown(false);
    }

    function handleMouseMove(e: MouseEvent): void {
      mouseMove += Math.abs(e.movementX) + Math.abs(e.movementY);
      setDragging(mouseMove > MoveDragThreshold);
    }

    if (mouseDown) {
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [mouseDown]);

  function handleMouseDown(): void {
    setMouseDown(true);
    setDragging(false);
  }

  return {
    handleMouseDown,
    dragging,
  };
}

const PageBodyPartnerLinks = ({
  heading,
  children
}: PageBodyPartnerLinksProps) => (
  <div
    className="toolkit"
  >
    <HTMLContent html={heading} />

    <div className="toolkit__list">
      <Slider
        {...settings}
      >
        {children}
      </Slider>
    </div>
  </div>
)

type CardProps = {
  text?: string
  href?: string
  target?: string
}

const Card = ({
  text,
  href,
  target
}: CardProps) => {
  const {
    handleMouseDown,
    dragging,
  } = useDragDetection();

  function handleChildClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    if (dragging) {
      e.preventDefault();
    }
  }

  return text && href ? (
    <div>
      <Link
        className="link-card"
        href={href}
        target={target}
        onMouseDownCapture={handleMouseDown}
        onClickCapture={handleChildClick}
      >
        <p>{text}</p>
      </Link>
    </div>
  ) : null
}

PageBodyPartnerLinks.Card = Card

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyPartnerLinksFragment, typeof mapDataToContext>) => ({
  heading: getRichText(data?.primary?.heading),
  children: data?.items?.map((item) => (
    <PageBodyPartnerLinks.Card
      key={item?.link?.url}
      text={item?.link_text}
      href={item?.link?.url}
      target={item?.link?.target}
    />
  )) as React.ReactNode
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyPartnerLinks on PrismicPageBodyPartnerLinks {
    primary {
      heading {
        text
        html
      }
    }
    items {
      link_text
      link {
        url
        target
      }
    }
  }
`

export default PageBodyPartnerLinks