import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import GatsbyImage from 'gatsby-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'

export type PageBodyImagePromoProps = ReturnType<typeof mapDataToProps> & PageTemplateEnhancerProps

const PageBodyImagePromo = ({
  heading,
  linkText,
  linkHref,
  linkTarget,
  imageFluid,
  imageAlt
}: PageBodyImagePromoProps) => heading ? (
  <div className="image-promo">
    {imageFluid && (
      <GatsbyImage fluid={imageFluid} alt={imageAlt}/>
    )}

    <div className="image-promo__copy">
      <HTMLContent html={heading} />

      {linkHref && linkText && (
        <Link className="button" href={linkHref} target={linkTarget}>
          {linkText || 'Learn More'}
        </Link>
      )}
    </div>
  </div>
) : null

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyImagePromoFragment, typeof mapDataToContext>) => ({
  heading: getRichText(data?.primary?.heading),
  linkText: data?.primary?.link_text,
  linkHref: data?.primary?.link?.url,
  linkTarget: data?.primary?.link?.target,
  imageFluid: data?.primary?.image?.fluid,
  imageAlt: data?.primary?.image?.alt
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyImagePromo on PrismicPageBodyImagePromo {
    primary {
      heading {
        text
        html
      }
      link_text
      link {
        url
        target
      }
      image {
        alt
        fluid(maxWidth: 1600) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyImagePromo
